import React from 'react';
import { Container, Jumbotron } from 'react-bootstrap';

import Layout from '../components/layout';
import '../assets/styles.scss';

const IndexPage = () => (
  <Layout>
    <Container fluid>
      <Jumbotron>
        <h1>Hello, world!</h1>
        <p>
          This is a simple hero unit, a simple jumbotron-style component for calling extra attention
          to featured content or information.
        </p>
      </Jumbotron>
    </Container>
  </Layout>
);

export default IndexPage;
